import './WalyInputField.scss';
import React, { ReactElement } from 'react';

export enum WalyInputType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  Textarea = 'textarea',
}

interface WalyInputProps {
  type: WalyInputType;
  name: string;
  placeholder?: string;
  label?: string;
}

export const WalyInputField: React.FC<WalyInputProps> = ({ type, name, placeholder, label }): ReactElement => {
  if (type === WalyInputType.Textarea) {
    return (
      <div className="WalyInputField">
        {label ? <label htmlFor={name}>{label}</label> : null}
        <textarea name={name} id={name} placeholder={placeholder ? placeholder : ''}></textarea>
      </div>
    );
  }
  return (
    <div className="WalyInputField">
      {label ? <label htmlFor={name}>{label}</label> : null}
      <input type={type} name={name} id={name} placeholder={placeholder ? placeholder : ''} />
    </div>
  );
};
