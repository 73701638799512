import './Sidebar.Component.scss';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarComponentProps } from './Sidebar.Component';

export const SidebarView: React.FC<SidebarComponentProps> = ({ navigationItems }): ReactElement => {
  return (
    <aside className="NavigationHolder DisableTextSelection">
      <nav className="NavSidebar">
        {navigationItems.map((navigationItemGroup) => {
          return (
            <div className={`NavItemGroup ${navigationItemGroup.key}`} key={navigationItemGroup.key}>
              <span className="NavTitle">{navigationItemGroup.name}</span>
              {navigationItemGroup.items.map((navigationItem) => {
                return (
                  <NavLink className={`NavItem ${navigationItem.key}`} to={navigationItem.link} key={navigationItem.key} activeClassName="active">
                    <span className="NavItemIcon">{navigationItem.icon()}</span>
                    <span className="NavItemTitle">{navigationItem.title}</span>
                  </NavLink>
                );
              })}
            </div>
          );
        })}
      </nav>
    </aside>
  );
};
