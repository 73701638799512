import React, { ReactElement } from 'react';
import { WalyButton, WalyButtonType } from '../../../../Components/WalyButton/WalyButton';
import { WalyInputField, WalyInputType } from '../../../../Components/WalyInputField/WalyInputField';

export const PasswordResetPage: React.FC = (): ReactElement => {
  return (
    <div className="PasswordResetPage">
      <h1>Recuperación</h1>
      <p>Ingresa el email con el que tienes registrada tu cuenta. Te enviaremos un enlace en el cual podrás reestablecer tu contraseña.</p>
      <WalyInputField type={WalyInputType.Email} label="Email" name="user-email" placeholder="hola@ejemplo.com"></WalyInputField>
      <WalyButton type={WalyButtonType.Raised}>Enviame el enlace</WalyButton>
      <hr data-content="O" />
      <WalyButton linkTo="/acceder">Ya recordé mi contraseña</WalyButton>
      <WalyButton type={WalyButtonType.Flat} linkTo="/registro">
        Quiero crear una cuenta nueva
      </WalyButton>
    </div>
  );
};
