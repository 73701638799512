import React, { ReactElement, useEffect } from 'react';
import { BiExit } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { WalyButton, WalyButtonType } from '../../../../Components/WalyButton/WalyButton';
import { setPageTitle } from '../../../../Store/AppSettings/AppSettingsActions';

export const SettingsPage: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('Configuración'));
  });
  return (
    <div className="SettingsPage">
      <h1>Configuración</h1>
      <WalyButton linkTo="/acceder" type={WalyButtonType.Small}>
        <BiExit /> Cerrar Sesión
      </WalyButton>
    </div>
  );
};
