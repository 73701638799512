import './WalyButton.scss';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export enum WalyButtonType {
  Raised = 'Raised',
  Flat = 'Flat',
  Transparent = 'Transparent',
  Small = 'Small',
  SmallTransparent = 'SmallTransparent',
}

interface WalyButtonProps {
  linkTo?: string;
  type?: WalyButtonType;
  onClick?: () => void;
}

export const WalyButton: React.FC<WalyButtonProps> = ({ children, linkTo, type, onClick }): ReactElement => {
  const defaultButtonType: WalyButtonType = WalyButtonType.Transparent;
  if (linkTo) {
    return (
      <Link to={linkTo} className={`WalyButton Type${type ? type : defaultButtonType} DisableTextSelection`}>
        {children}
      </Link>
    );
  } else {
    return (
      <button className={`WalyButton Type${type ? type : defaultButtonType} DisableTextSelection`} onClick={onClick}>
        {children}
      </button>
    );
  }
};
