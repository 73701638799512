import './Toolbar.Component.scss';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { WalyStore } from '../../../../Store/WalyStore';
import { ToolbarView } from './Toolbar.View';

export const Toolbar: React.FC = ({ children }): ReactElement => {
  const pageTitle = useSelector(({ appSettings }: WalyStore) => appSettings.pageTitle);
  return <ToolbarView pageTitle={pageTitle}>{children}</ToolbarView>;
};
