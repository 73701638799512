import './DashboardLayout.scss';
import React, { ReactElement } from 'react';
import { BiBarChart, BiCog, BiCommentDetail, BiFolder, BiLayer, BiPlus, BiSpreadsheet, BiTransfer, BiWallet } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { WalyButton, WalyButtonType } from '../../Components/WalyButton/WalyButton';
import { setTheme } from '../../Store/AppSettings/AppSettingsActions';
import { WalyTheme } from '../../Store/AppSettings/AppSettingsStateModel';
import { WalyStore } from '../../Store/WalyStore';
import { Sidebar } from './Components/Sidebar/Sidebar.Component';
import { Toolbar } from './Components/Toolbar/Toolbar.Component';
import { AccountsPage } from './Pages/Accounts/AccountsPage';
import { CategoriesPage } from './Pages/Categories/CategoriesPage';
import { FinanceStatusPage } from './Pages/FinanceStatus/FinanceStatusPage';
import { HelpFeedbackPage } from './Pages/HelpFeedback/HelpFeedbackPage';
import { SettingsPage } from './Pages/Settings/SettingsPage';
import { SplitterPage } from './Pages/Splitter/SplitterPage';
import { TransactionsPage } from './Pages/Transactions/TransactionsPage';
import { TransactionsReportPage } from './Pages/TransactionsReports/TransactionsReportsPage';

export interface NavigationItem {
  key: string;
  title: string;
  link: string;
  component: React.FC<any>;
  icon: () => ReactElement;
}

export interface NavigationItemGroup {
  key: string;
  name?: string;
  items: NavigationItem[];
}

export const DashboardLayout: React.FC = (): ReactElement => {
  const navigationItems: NavigationItemGroup[] = [
    {
      key: 'GeneralGroup',
      name: 'Panel de control',
      items: [
        { key: 'FinanceStatusPage', title: 'Estado financiero', link: '/estado-financiero', icon: () => <BiBarChart />, component: FinanceStatusPage },
        {
          key: 'TransactionsReportPage',
          title: 'Resumen de movimientos',
          link: '/resumen-de-movimientos',
          component: TransactionsReportPage,
          icon: () => <BiSpreadsheet />,
        },
      ],
    },
    {
      key: 'TransactionsGroup',
      name: 'Movimientos',
      items: [
        { key: 'AccountsPage', title: 'Cuentas', link: '/cuentas', icon: () => <BiWallet />, component: AccountsPage },
        { key: 'TransactionsPage', title: 'Transacciones', link: '/transacciones', icon: () => <BiTransfer />, component: TransactionsPage },
        { key: 'CategoriesPage', title: 'Categorías', link: '/categorias', icon: () => <BiFolder />, component: CategoriesPage },
      ],
    },
    {
      key: 'ToolsGroup',
      name: 'Herramientas',
      items: [{ key: 'SplitterPage', title: 'Dividir cuenta', link: '/dividir-cuenta', icon: () => <BiLayer />, component: SplitterPage }],
    },
    {
      key: 'BottomGroup',
      items: [
        { key: 'SettingsPage', title: 'Configuración', link: '/configuracion', icon: () => <BiCog />, component: SettingsPage },
        { key: 'HelpFeedbackPage', title: 'Ayuda y feedback', link: '/ayuda-y-feedback', icon: () => <BiCommentDetail />, component: HelpFeedbackPage },
      ],
    },
  ];

  const dispatch = useDispatch();
  const theme = useSelector(({ appSettings }: WalyStore) => appSettings.theme);

  const toggleDarkMode = () => dispatch(setTheme(theme === WalyTheme.Dark ? WalyTheme.Light : WalyTheme.Dark));

  return (
    <div className="DashboardLayout">
      <Redirect exact from="/dashboard" to="/estado-financiero" />
      <Toolbar>
        <WalyButton type={WalyButtonType.SmallTransparent} onClick={toggleDarkMode}>
          <BiPlus /> Añadir Transacción
        </WalyButton>
      </Toolbar>
      <Sidebar navigationItems={navigationItems} />
      <main className="ContentHolder">
        {navigationItems.map((navigationItemGroup) => {
          return navigationItemGroup.items.map((navigationItem) => {
            return <Route path={navigationItem.link} key={navigationItem.key} component={navigationItem.component} />;
          });
        })}
      </main>
    </div>
  );
};
