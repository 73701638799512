import './WalyApp.scss';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthLayout } from './Layouts/Auth/AuthLayout';
import { DashboardLayout } from './Layouts/Dashboard/DashboardLayout';
import { WebsiteLayout } from './Layouts/Website/WebsiteLayout';
import { WalyStore } from './Store/WalyStore';

type WalyTheme = 'Dark' | 'Light';

const validRoutes = {
  website: ['/', '/apps'],
  auth: ['/acceder', '/registro', '/recuperacion-de-contrasena'],
  dashboard: [
    '/dashboard',
    '/estado-financiero',
    '/resumen-de-movimientos',
    '/cuentas',
    '/transacciones',
    '/transacciones',
    '/categorias',
    '/dividir-cuenta',
    '/configuracion',
    '/ayuda-y-feedback',
  ],
};

export const WalyApp: React.FC = (): ReactElement => {
  const theme = useSelector(({ appSettings }: WalyStore) => appSettings.theme);
  return (
    <div className={`WalyApp Waly${theme}`}>
      <BrowserRouter>
        <Switch>
          <Route exact path={validRoutes.website} component={WebsiteLayout} />
          <Route path={validRoutes.auth} component={AuthLayout} />
          <Route path={validRoutes.dashboard} component={DashboardLayout} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};
