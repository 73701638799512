import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../../../Store/AppSettings/AppSettingsActions';

export const TransactionsReportPage: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('Resumen de movimientos'));
  });
  return <h1>Resumen de Movimientos</h1>;
};
