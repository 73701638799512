import './AuthLayout.scss';
import React, { ReactElement } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import WalyLogo from '../../../resources/svg/logo.svg';
import { LoginPage } from './Pages/Login/LoginPage';
import { PasswordResetPage } from './Pages/PasswordReset/PasswordResetPage';
import { RegisterPage } from './Pages/Register/RegisterPage';

export const AuthLayout: React.FC = (): ReactElement => {
  return (
    <div className="AuthLayout">
      <div className="CenteredContainer">
        <Link className="WalyLogo" to="/">
          <img src={WalyLogo} alt="Waly" />
        </Link>
        <Switch>
          <Route path="/acceder" component={LoginPage} />
          <Route path="/registro" component={RegisterPage} />
          <Route path="/recuperacion-de-contrasena" component={PasswordResetPage} />
        </Switch>
      </div>
    </div>
  );
};
