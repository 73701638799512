import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../../../Store/AppSettings/AppSettingsActions';

export const FinanceStatusPage: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('Estado financiero'));
  });
  return <h1>¡Hola, Victor!</h1>;
};
