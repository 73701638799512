import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../../../Store/AppSettings/AppSettingsActions';

export const CategoriesPage: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('Categorías'));
  });
  return <h1>Categorías de ingresos y egresos</h1>;
};
