import './WebsiteLayout.scss';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import WalyLogo from '../../../resources/svg/logo.svg';

export const WebsiteLayout: React.FC = (): ReactElement => {
  return (
    <div className="WebsiteLayout">
      <header>
        <Link to="/">
          <img src={WalyLogo} alt="Waly" />
        </Link>
        <nav>
          <Link to="/acceder">Login</Link>
          <Link to="/dashboard">Dashboard</Link>
        </nav>
      </header>
    </div>
  );
};
