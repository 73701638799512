import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../../../Store/AppSettings/AppSettingsActions';

export const HelpFeedbackPage: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('Ayuda y Feedback'));
  });
  return <h1>Ayuda y feedback</h1>;
};
