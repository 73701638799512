import { WalyStoreAction } from '../WalyAction';
import { SetPageTitleAction, SetThemeAction } from './AppSettingsActions';
import { AppSettingsStateTree, initialAppSettingsStateTree } from './AppSettingsStateModel';

export const appSettingsReducer = (state: AppSettingsStateTree = initialAppSettingsStateTree, action: any): AppSettingsStateTree => {
  switch (action.type) {
    case WalyStoreAction.SetTheme:
      const { theme } = action as SetThemeAction;
      return { ...state, theme };
    case WalyStoreAction.SetPageTitle:
      const { pageTitle } = action as SetPageTitleAction;
      return { ...state, pageTitle };
    default:
      return state;
  }
};
