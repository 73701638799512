import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../../../Store/AppSettings/AppSettingsActions';

export const TransactionsPage: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('Transacciones'));
  });
  return <h1>Transacciones</h1>;
};
