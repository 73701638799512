import { WalyStoreAction } from '../WalyAction';
import { WalyTheme } from './AppSettingsStateModel';

interface GenericAction {
  type: WalyStoreAction;
}

export interface SetThemeAction extends GenericAction {
  theme: WalyTheme;
}
export const setTheme = (theme: WalyTheme): SetThemeAction => {
  return { type: WalyStoreAction.SetTheme, theme };
};

export interface SetPageTitleAction extends GenericAction {
  pageTitle: string;
}
export const setPageTitle = (pageTitle: string): SetPageTitleAction => {
  return { type: WalyStoreAction.SetPageTitle, pageTitle };
};
