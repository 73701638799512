import React, { ReactElement } from 'react';
import { WalyButton, WalyButtonType } from '../../../../Components/WalyButton/WalyButton';
import { WalyInputField, WalyInputType } from '../../../../Components/WalyInputField/WalyInputField';

export const LoginPage: React.FC = (): ReactElement => {
  return (
    <div className="LoginPage">
      <h1>¡Bienvenido!</h1>
      <p>Inicia sesión para acceder a tus finanzas.</p>
      <WalyInputField type={WalyInputType.Email} label="Email" name="user-email" placeholder="hola@ejemplo.com"></WalyInputField>
      <WalyInputField type={WalyInputType.Password} label="Contraseña" name="user-password" placeholder="●●●●●●●●●●●●●●●●"></WalyInputField>
      <WalyButton type={WalyButtonType.Raised} linkTo="/dashboard">
        Acceder
      </WalyButton>
      <WalyButton linkTo="/recuperacion-de-contrasena">Olvidé mi contraseña</WalyButton>
      <hr data-content="O" />
      <p>¿Aún no tienes una cuenta?</p>
      <WalyButton type={WalyButtonType.Flat} linkTo="/registro">
        Crear una cuenta
      </WalyButton>
    </div>
  );
};
