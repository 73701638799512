import React, { ReactElement } from 'react';
import { NavigationItemGroup } from '../../DashboardLayout';
import { SidebarView } from './Sidebar.View';

export interface SidebarComponentProps {
  navigationItems: NavigationItemGroup[];
}

export const Sidebar: React.FC<SidebarComponentProps> = ({ navigationItems }): ReactElement => {
  return <SidebarView navigationItems={navigationItems}></SidebarView>;
};
