import React, { ReactElement } from 'react';
import { WalyButton, WalyButtonType } from '../../../../Components/WalyButton/WalyButton';
import { WalyInputField, WalyInputType } from '../../../../Components/WalyInputField/WalyInputField';

export const RegisterPage: React.FC = (): ReactElement => {
  return (
    <div className="RegisterPage">
      <h1>Crea una cuenta</h1>
      <p>Crear una cuenta es totalmente gratis y rápido.</p>
      <WalyInputField type={WalyInputType.Email} label="Email" name="user-email" placeholder="hola@ejemplo.com"></WalyInputField>
      <WalyInputField type={WalyInputType.Password} label="Contraseña" name="user-password" placeholder="●●●●●●●●●●●●●●●●"></WalyInputField>
      <WalyInputField
        type={WalyInputType.Password}
        label="Verificación de contraseña"
        name="user-password-verification"
        placeholder="●●●●●●●●●●●●●●●●"></WalyInputField>
      <WalyButton type={WalyButtonType.Raised}>Crear cuenta</WalyButton>
      <hr data-content="O" />
      <WalyButton linkTo="/acceder">Ya tengo una cuenta</WalyButton>
      <WalyButton linkTo="/recuperacion-de-contrasena">Ya tengo una cuenta pero olvidé mi contraseña</WalyButton>
    </div>
  );
};
