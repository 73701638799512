import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import WalyLogo from '../../../../../resources/svg/logo.svg';

interface ToolbarViewProps {
  pageTitle: string;
}

export const ToolbarView: React.FC<ToolbarViewProps> = ({ pageTitle, children }): ReactElement => {
  return (
    <header className="HeaderHolder DisableTextSelection">
      <NavLink className="WalyLogo" to="/estado-financiero">
        <img src={WalyLogo} alt="Waly" />
      </NavLink>
      <h1 className="PageTitle">{pageTitle}</h1>
      {children ? <div className="HeaderToolsArea">{children}</div> : null}
    </header>
  );
};
