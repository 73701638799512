import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { appSettingsReducer } from './AppSettings/AppSettingsReducer';
import { AppSettingsStateTree } from './AppSettings/AppSettingsStateModel';

export interface WalyStore {
  appSettings: AppSettingsStateTree;
}

interface WalyStoreProps {
  // initialState: WalyStateTree;
}

export const WalyStore: React.FC<WalyStoreProps> = ({ children }): ReactElement => {
  const rootReducer = combineReducers({
    appSettings: appSettingsReducer,
  });
  //@ts-ignore
  const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
  const store = createStore(rootReducer, reduxDevTools);
  return <Provider store={store}>{children}</Provider>;
};
