export enum WalyTheme {
  Light = 'Light',
  Dark = 'Dark',
}

export interface AppSettingsStateTree {
  theme: WalyTheme;
  pageTitle: string;
}

export const initialAppSettingsStateTree: AppSettingsStateTree = {
  theme: WalyTheme.Light,
  pageTitle: 'Redux Title 🧙‍♂️',
};
